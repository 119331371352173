import { $ApiFileType, $ApiLayoutFileType, $Date } from "shared/api/types";
import { z } from "zod";
import { $CatalogViewVariantEnum } from "../categories/types";
import { $MetaDataPage } from "../pages/types";
import { $Contractor } from "../user/types";

// TODO: используется в корзине и История КП
export type ProductCategory = z.infer<typeof $ProductCategory>;
const $ProductCategory = z.object({
    id: z.number(),
    slug: z.string().nullish(),
    name: z.string(),
    parent: z.object({ id: z.number(), slug: z.string().nullish(), name: z.string() }).nullable(),
    variantView: $CatalogViewVariantEnum,
});
// TODO: используется в корзине и История КП
export const $Warehouse = z.object({
    id: z.number(),
    title: z.string(),
});
// TODO: используется в корзине и История КП
export const $WarehouseItem = z.object({
    id: z.number(),
    available: z.number(),
    reserved: z.number(),
    updatedAt: $Date,
    warehouse: $Warehouse,
});

// TODO: используется в корзине и История КП
export type ProductMarker = z.infer<typeof $ProductMarker>;
export const $ProductMarker = z.object({
    color: z.string(),
    id: z.number(),
    content: z.string(),
});
// TODO: используется в корзине и История КП
export const $Color = z.object({
    code: z.string().nullish(),
    id: z.number(),
    value: z.string(),
    isException: z.boolean().nullish(),
});

// TODO: используется в корзине и История КП
export const $ProductOffer = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string().nullish(),
    model: z.string(),
    // в копейках
    price: z.number(),
    base_price: z.number().nullish(),
    hasOwnDiscount: z.boolean().nullish(),
    extraColors: z
        .array(
            z.object({
                id: z.number(),
                title: z.string(),
            })
        )
        .nullish(),
    count: z.number(),
    stock: z.array($WarehouseItem),
    images: z.array($ApiFileType).min(1),
    cover: $ApiFileType,
    color: z.object({
        id: z.number(),
        title: z.string(),
    }),
    variation: z.string().nullish(),
    on_order: z.boolean(),
    on_order_label: z.string(),
    on_order_text: z.string(),
});

// TODO: используется в корзине и История КП
export const $Product = z.object({
    id: z.number(),
    name: z.string(),
    material: z.string().nullish(),
    offers: z.array($ProductOffer).min(1),
    marks: z.array($ProductMarker).nullish(),
    subtitle: z.string().nullish(),
    slug: z.string().nullish(),
    unit_volume: z.string().nullish(),
    unit_weight: z.string().nullish(),
    categories: z.array($ProductCategory),
    on_order: z.boolean(),
    on_order_label: z.string(),
    on_order_text: z.string(),
    is_rotate: z.boolean(),
});

// TODO используется в истории КП
export type ProductDrawing = z.infer<typeof $ProductDrawing>;
export const $ProductDrawing = z.object({
    id: z.number(),
    slug: z.string().nullish(),
    tooltipText: z.string(),
    name: z.string(),
});

// TODO используется в истории КП
export const $ProductShow = $Product
    .extend({
        attributes: z.array(
            z.object({
                id: z.number(),
                key: z.string(),
                value_unit: z.string(),
                name: z.string(),
                value: z.string(),
            })
        ),
        description: z.string().nullish(),
        drawingTypes: z.array($ProductDrawing),
        layoutsDefault: z.array($ApiLayoutFileType),
        categories: z.array($ProductCategory),
        unit_volume: z.string(),
        unit_weight: z.string(),
    })
    .merge($MetaDataPage);

// TODO параметры запроса для добавления товара в корзину на детальной странице
export type PrintingAreasRequest = z.infer<typeof $PrintingAreasRequest>;
export const $PrintingAreasRequest = z.object({
    id: z.number(),
    printingMethodId: z.number(),
    square: z.number().nullish(),
    additionalOptionIds: z.array(z.number()).nullish(),
    colorIds: z.array(z.number()).nullish(),
    consolidatedAdditionalOptions: z
        .array(
            z.object({
                id: z.number(),
                field: z.string(),
            })
        )
        .nullish(),
});
// TODO параметры запроса для расчёта стоимости на детальной странице
export type ProductOfferCalculatePayload = z.infer<typeof $ProductOfferCalculatePayload>;
export const $ProductOfferCalculatePayload = z.object({
    offerId: z.number(),
    quantity: z.number(),
    printingAreas: z.array($PrintingAreasRequest).nullish(),
    isSignal: z.boolean(),
});

// TODO ответ запроса для расчёта стоимости на детальной странице
export type CalculateError = z.infer<typeof $CalculateError>;
export const $CalculateError = z.object({
    type: z.literal("quantity").or(z.literal("totalBaseCost")),
    value: z.string(),
});
// TODO ответ запроса для расчёта стоимости на детальной странице
export type CalculateErrors = z.infer<typeof $CalculateErrors>;
export const $CalculateErrors = z.object({
    items: z.record(z.coerce.number(), $CalculateError.array()),
});
// TODO ответ запроса для расчёта стоимости на детальной странице
export type ProductOfferCalculateResponse = z.infer<typeof $ProductOfferCalculate>;
export const $ProductOfferCalculate = z.object({
    basePrice: z.object({
        itemPrice: z.number(),
        circulationPrice: z.number(),
        drawingPrice: z.number(),
        serviceFee: z.number(),
        total: z.number(),
    }),
    errors: $CalculateErrors.or(z.unknown().array()).optional(),
    discountPrice: z
        .object({
            itemPrice: z.number(),
            circulationPrice: z.number(),
            drawingPrice: z.number(),
            serviceFee: z.number(),
            total: z.number(),
        })
        .optional(),
});
// TODO: используется в корзине и История КП
export const $PrintingMethod = z.object({
    id: z.number(),
    title: z.string(),
    tooltip: z.string(),
    isCentimeter: z.boolean(),
    isColor: z.boolean(),
    isOfferWithSubstrate: z.boolean(),
    notifications: z.array(z.string()).optional(),
    maxCountColors: z.number().nullish(),
    additionalOptions: z.array(
        z.object({
            amount: z.number(),
            id: z.number(),
            title: z.string(),
            unit_type: z.string().nullish(),
            group: z.string().nullish(),
            disable: z.boolean().nullish(),
            is_send: z.boolean().nullish(),
            selected: z.boolean().nullish(),
        })
    ),
    consolidatedAdditionalOptions: z
        .array(
            z.object({
                id: z.number(),
                title: z.string().nullable(),
                subTitle: z.string().nullable(),
                amount: z.number(),
                unitType: z.string(),
                onlySubTitle: z.boolean(),
                selected: z.boolean().optional(),
                is_send: z.boolean().optional(),
                disable: z.boolean().optional(),
            })
        )
        .optional(),
    colors: z.array($Color),
});

// TODO: используется в корзине и История КП
export type RectPrintingArea = z.infer<typeof $RectPrintingArea>;
export const $RectPrintingArea = z.object({
    id: z.number(),
    title: z.string(),
    type: z.literal("rect"),
    coordinates: z.object({
        height: z.number(),
        width: z.number(),
        left: z.number(),
        top: z.number(),
    }),
    printingMethods: z.array($PrintingMethod),
});

// TODO: используется в корзине и История КП
export type CirclePrintingArea = z.infer<typeof $CirclePrintingArea>;
export const $CirclePrintingArea = z.object({
    id: z.number(),
    title: z.string(),
    type: z.literal("circle"),
    coordinates: z.object({
        cx: z.number(),
        cy: z.number(),
        r: z.number(),
    }),
    printingMethods: z.array($PrintingMethod),
});

// TODO: используется в корзине и История КП
export type PrintingArea = z.infer<typeof $PrintingArea>;
export const $PrintingArea = $RectPrintingArea.or($CirclePrintingArea);

// TODO используется в Истории КП
export type ProductOfferShow = z.infer<typeof $ProductOfferShow>;
export const $ProductOfferShow = $ProductOffer.extend({
    product: $ProductShow,
    recommendations: $Product.array(),
    printingAreaImage: $ApiFileType
        .extend({
            view_box: z.object({
                height: z.string(),
                width: z.string(),
                left: z.string(),
                top: z.string(),
            }),
        })
        .nullish(),
    printingAreas: z.array($PrintingArea),
    on_order: z.boolean(),
    on_order_label: z.string(),
    on_order_text: z.string(),
});

// https://gitlab.addamant-work.ru/vivagifts/vivagifts-back/-/issues/9

export const $CartProductOffer = z.object({
    id: z.number(),
    name: z.string(),
    slug: z.string().nullish(),
    model: z.string(),
    price: z.number(),
    count: z.number(),
    variation: z.string().nullish(),
    cover: $ApiFileType,
    printingAreas: z.array($PrintingArea).optional(),
    product: $Product.omit({ offers: true, on_order: true, on_order_label: true, on_order_text: true }),
    warehouseItem: z
        .object({
            id: z.number(),
            available: z.number(),
            reserved: z.number(),
            updatedAt: z.string(),
            main: z.boolean(),
            warehouse: z.object({
                id: z.number(),
                title: z.string(),
                isActive: z.boolean(),
            }),
            pending: z.array(
                z.object({ id: z.number(), amount: z.number(), available: z.number(), expectedAt: z.coerce.date() })
            ),
        })
        .nullish(),
});

export const $OrderItemStatusName = z
    .literal("reserved")
    .or(z.literal("reservation_canceled"))
    .or(z.literal("preparing"))
    .or(z.literal("prepared"))
    .or(z.literal("ready_for_shipping"))
    .or(z.literal("shipped"));

export type OrderItemStatusName = z.infer<typeof $OrderItemStatusName>;

export const $OrdersStatus = z.object({ name: z.string(), displayName: z.string() });

export const $CartItem = z.object({
    id: z.number(),
    quantity: z.number(),
    quantityPending: z.number().optional(),
    baseItemCost: z.number(),
    baseItemCostAfterDiscount: z.number(),
    itemCostAfterDiscount: z.number(),
    itemCostBeforeDiscount: z.number(),
    itemPrintingsCost: z.number(),
    itemPrintingsCostAfterDiscount: z.number(),
    totalBaseCost: z.number(),
    totalBaseCostAfterDiscount: z.number(),
    totalCostAfterDiscount: z.number(),
    totalCostBeforeDiscount: z.number(),
    totalDiscount: z.number(),
    totalItemDiscount: z.number(),
    totalPrintingCost: z.number(),
    totalPrintingCostAfterDiscount: z.number(),
    totalPrintingDiscount: z.number(),
    status: $OrdersStatus.nullish(),
    isPrintingAreas: z.boolean().optional(),
    discountPercent: z.number().nullish(),
    discountPrintingMethodPercent: z.number().nullish(),
    isSignal: z.boolean().nullish(),
    productOffer: $CartProductOffer,
});
export type CartItem = z.infer<typeof $CartItem>;

export type ShippingMethodType = z.infer<typeof $ShippingMethodType>;
export const $ShippingMethodType = z.literal("simple").or(z.literal("delline"));

export const $ShippingMethodPriceModifierType = z.enum(["VALUE", "PERCENT"]);
export type ShippingMethod = z.infer<typeof $ShippingMethod>;
export const $ShippingMethod = z.object({
    id: z.number(),
    title: z.string(),
    description: z.string(),
    price: z.number(),
    isNeedAddress: z.boolean().nullish(),
    type: $ShippingMethodType.nullish(),
});

export type ShippingMethodGroup = z.infer<typeof $ShippingMethodGroup>;
export const $ShippingMethodGroup = z.object({
    id: z.number(),
    name: z.string(),
    shippingMethods: z.array($ShippingMethod),
});

export type ShippingMethodGroupsResponse = z.infer<typeof $ShippingMethodGroupsResponse>;
export const $ShippingMethodGroupsResponse = z.array($ShippingMethodGroup);

export const $productPrices = z.object({
    // Итого, без нанесений, без скидки
    baseCost: z.number(),
    // Персональная скидка на товар
    baseCostDiscount: z.number(),
    // Итого, без нанесений, со скидкой
    finalBaseCost: z.number(),
    // Нанесения, без скидки
    printingCost: z.number(),
    // Персональная скидка на нанесение
    printingDiscount: z.number(),
    // Нанесения, со скидкой
    finalPrintingCost: z.number(),
    // Итого, с нанесениями, без скидки
    totalCostBeforeDiscount: z.number(),
    // Итого, с нанесениями, со скидкой
    totalCostAfterDiscount: z.number(),
});

export type CartIndexError = z.infer<typeof $CartIndexError>;
export const $CartIndexError = z.object({
    type: z.literal("quantity").or(z.literal("totalBaseCost")),
    value: z.string(),
});

export type CartIndexErrors = z.infer<typeof $CartIndexErrors>;
export const $CartIndexErrors = z.object({
    items: z.record(z.coerce.number(), $CartIndexError.array()),
});

export type CartIndexNotification = z.infer<typeof $CartIndexNotification>;
export const $CartIndexNotification = z.object({
    type: z.literal("collect_quantity"),
    value: z.string(),
});

export type CartIndexNotifications = z.infer<typeof $CartIndexNotifications>;
export const $CartIndexNotifications = z.object({
    items: z.record(z.coerce.number(), $CartIndexNotification),
});

export const $CartIndex = z
    .object({
        id: z.number(),
        items: z.array($CartItem),
        shippingMethod: z
            .object({
                id: z.number().nullish(),
                title: z.string(),
                description: z.string().nullish(),
                price: z.number().nullish(),
                isNeedAddress: z.boolean().nullish(),
                type: $ShippingMethodType.nullish(),
                methodDetail: z
                    .object({
                        address: z.string().nullish(),
                        type: z.literal("delivery").or(z.literal("pickup")).nullish(),
                        cityId: z.number().nullish(),
                        cityAddress: z.string().nullish(),
                        terminalId: z.number().nullish(),
                        terminalAddress: z.string().nullish(),
                    })
                    .nullish(),
            })
            .nullish(),
        contractor: $Contractor.nullish(),
        layouts: $ApiFileType.array(),
        comment: z.string().optional(),
        shippingAddress: z.string().nullish(),
        serviceFee: z.number(),
        errors: $CartIndexErrors.or(z.unknown().array()).optional(),
        service_payment_threshold: z.string().or(z.number()).optional(),
        service_payment: z.string().or(z.number()).optional(),
        total_weight: z.number().nullish(),
        total_volume: z.number().nullish(),
        total_count: z.number().nullish(),
        total: z.number(),
        isSample: z.boolean().nullish(),
        isSampleTotal: z.number(),
        notifications: $CartIndexNotifications.nullish(),
    })
    .merge($productPrices);

export type CartIndex = z.infer<typeof $CartIndex>;

export const $CreateCartItem = z.object({
    productOfferId: z.number(),
    // Количество к заказу
    quantity: z.number(),
    // Общее количество для резерва
    quantityPending: z.number().optional(),
    printingAreas: z.array($PrintingAreasRequest).nullish(),
    isSignal: z.boolean().nullish(),
});
export type CreateCartItem = z.infer<typeof $CreateCartItem>;

export const $UpdateCartItem = $CreateCartItem.omit({ productOfferId: true });
export type UpdateCartItem = z.infer<typeof $UpdateCartItem>;
export interface UpdateCartItemRequest {
    cartItemId: number;
    changedItem: UpdateCartItem;
}

export const $CartCount = z.object({
    count: z.number(),
});

export const $CartGuestId = z.object({
    deviceId: z.string(),
});

export const $UpdateCartRequest = z.object({
    contractorId: z.number().nullish(),
    layoutIds: z.number().array(),
    shippingMethodId: z.number().nullish(),
    shippingAddress: z.string().nullish(),
    comment: z.string().nullish(),
    isSample: z.boolean().nullish(),
    shippingMethodSettings: z.any(),
});
export type UpdateCartRequest = z.infer<typeof $UpdateCartRequest>;

export const $Finances = z.object({});

export type ShippingMethodCity = z.infer<typeof $ShippingMethodCity>;
export const $ShippingMethodCity = z.object({ id: z.number(), value: z.string() });

export type GetShippingMethodCityResponse = z.infer<typeof $GetShippingMethodCityResponse>;
export const $GetShippingMethodCityResponse = z.array($ShippingMethodCity);
